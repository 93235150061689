import { toast } from "react-hot-toast";

class ApiService {
  constructor() {
    this.BASE_URL = process.env.REACT_APP_API_BASE_URL;
    this.CURRENT_VERSION = process.env.REACT_APP_VERSION;

    this.accessToken = localStorage.getItem("AccessToken") || "";
    this.refreshToken = localStorage.getItem("RefreshToken") || "";
  }

  setAccessToken(token) {
    this.accessToken = token;
    localStorage.setItem("AccessToken", token);
  }

  setRefreshToken(token) {
    this.refreshToken = token;
    localStorage.setItem("RefreshToken", token);
  }

  async refreshAccessToken() {
    if (!this.refreshToken) {
      throw new Error("No refresh token available.");
    }

    try {
      const response = await fetch(`${this.BASE_URL}/refresh`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refreshToken: this.refreshToken }),
      });

      if (!response.ok) {
        throw new Error("Failed to refresh token.");
      }

      const data = await response.json();
      this.setAccessToken(data.accessToken);
      return data.accessToken;
    } catch (error) {
      console.error("Failed to refresh token: ", error);
      throw error;
    }
  }

  buildHeaders(options) {
    const headers = {
      ...(options?.headers || {}),
      ...(this.accessToken
        ? { Authorization: `Bearer ${this.accessToken}` }
        : {}),
    };

    if (!headers["Content-Type"] && !(options?.body instanceof FormData)) {
      headers["Content-Type"] = "application/json";
    }

    return headers;
  }

  async fetchWithRefreshToken(endpoint, options, responseType = "json") {
    try {
      let response = await fetch(`${this.BASE_URL}${endpoint}`, {
        ...options,
        headers: this.buildHeaders(options),
        body:
          options?.body instanceof FormData
            ? options.body
            : JSON.stringify(options.body),
      });

      if (response.status === 403) {
        toast.error("You are not authorized to perform this action");
        this.logout();
        return;
      }

      if (response.status === 401) {
        await this.refreshAccessToken();
        response = await fetch(`${this.BASE_URL}${endpoint}`, {
          ...options,
          headers: this.buildHeaders(options),
          body:
            options?.body instanceof FormData
              ? options.body
              : JSON.stringify(options.body),
        });
      }

      const serverVersion = response.headers.get("X-VERSION");
      if (serverVersion && serverVersion !== this.CURRENT_VERSION) {
        toast("A new version is available. The page will refresh.", {
          icon: "👏",
        });
        setTimeout(() => window.location.reload(), 3000);
      }

      if (response.ok) {
        return responseType === "blob" ? response.blob() : response.json();
      } else {
        let errorMessage = "Unknown error";
        try {
          const errorData = await response.json();
          errorMessage = errorData.message || errorMessage;
        } catch (err) {
          console.error("Error parsing JSON: ", err);
        }
        throw new Error(errorMessage);
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        toast.error("Network error, check your connection.");
      }
      throw error;
    }
  }

  get(endpoint, responseType = "json") {
    return this.fetchWithRefreshToken(
      endpoint,
      { method: "GET" },
      responseType
    );
  }

  post(endpoint, body, responseType = "json") {
    return this.fetchWithRefreshToken(
      endpoint,
      { method: "POST", body },
      responseType
    );
  }

  put(endpoint, body) {
    return this.fetchWithRefreshToken(endpoint, { method: "PUT", body });
  }

  delete(endpoint) {
    return this.fetchWithRefreshToken(endpoint, { method: "DELETE" });
  }
}

export default new ApiService();
