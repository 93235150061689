import React from "react";
import "./OrganizationKeyEntry.css";
import { useAuth0 } from "@auth0/auth0-react";

export default function OrganizationKeyEntry({
  user,
  onSubmit,
  organizationKey,
  setOrganizationKey,
}) {
  const { logout } = useAuth0();
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  const handleLogout = () => {
    localStorage.clear();
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  return (
    <div className="org-key-container">
      <div className="org-key-card">
        <div className="org-key-header">
          <h2 className="org-key-title">Welcome!</h2>
          <p className="org-key-description">
            Hi {user?.email},<br />
            It looks like this is your first time here.
            <br />
            Please enter your organization key to continue.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="org-key-form">
          <input
            type="text"
            placeholder="Enter your organization key"
            value={organizationKey}
            onChange={(e) => setOrganizationKey(e.target.value)}
            className="org-key-input"
            required
          />
          <button type="submit" className="org-key-button">
            Join Organization
          </button>
          <button
            type="button"
            className="logout-button"
            onClick={() => {
              handleLogout();
            }}
          >
            Logout
          </button>
        </form>
      </div>
    </div>
  );
}
