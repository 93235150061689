import React, { useState, useRef } from "react";
import Image from "../Image";
import styles from "./promptAssistant.module.sass";
import CustomTooltip from "../CustomTooltip";
import TooltipData from "../CustomTooltip/TooltipsData";

const PromptAssistant = ({ setPrompt, artifact, setIsUsingAssistant }) => {

  const handleAssistantClick = () => {
    const defaultText = artifact?.type_data?.prompt_assistant_template

    setIsUsingAssistant(!setIsUsingAssistant);
    setPrompt((currentText) =>
      currentText === defaultText ? "" : defaultText
    );
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <CustomTooltip title={TooltipData["PromptAssistant"]} placement="left">
        <button
          className={styles.promtpAssistantButton}
          onClick={handleAssistantClick}
        >
          <Image
            className={styles.pic}
            src={`${process.env.PUBLIC_URL}/images/assistance_icon.png`}
            alt="assistant"
          />
        </button>
      </CustomTooltip>
    </div>
  );
};

export default PromptAssistant;
