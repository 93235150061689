const TooltipData = {
    Download: "Download",
    ReferencesOptions: "References",
    DimensionsOptions: "Dimensions",
    ImprovePrompt: "Improve the prompt to be more extensive",
    Settings: "Settings",
    Strength: <>Adjust the prompt strength when providing the image. <br /> 100% fully ignores image information;<br />Lower it to boost creativity.</>,
    Forge: "Forge",
    Variations: "Variations",
    Regenerate: "Regenerate",
    Inpainting: "Inpainting",
    Erase: "Erase",
    ColorPicker: "Color Picker",
    ColorPickerHistory: "Last 3 used colors",
    PaintOver: "Paint Over",
    MagicCut: "Magic Cut",
    EditPrompt: "Edit Prompt",
    ComingSoon: "COMING SOON!",
    Image: {
        text: "Use a stock image to set the vibe and let AI creatively interpret your concept.",
        imageUrl: `${process.env.PUBLIC_URL}/images/image_variations.png`,

    },
    Detailed: {
        text: "Use a refined sketch for precise detail enhancement; the AI fills in the finer elements.",
        imageUrl: `${process.env.PUBLIC_URL}/images/detailed.png`,
    },
    Concept: {
        text: "Provide a quick sketch for the AI to build upon, keeping details flexible.",
        imageUrl: `${process.env.PUBLIC_URL}/images/concept.png`,
    },
    Artline: {
        text: "Outline the form and structure, letting the AI work within your defined lines.",
        imageUrl: `${process.env.PUBLIC_URL}/images/artline.png`,
    },
    Pose: {
        text: "Provide a reference image to capture the exact pose, leaving other elements adjustable",
        imageUrl: `${process.env.PUBLIC_URL}/images/pose.png`,
    },
    PromptAssistant: "Prompt Assistant",

}

export default TooltipData
