import React from "react";
import { useModalPreview } from "../../contexts/ModalPreviewContext";
import CreationCard from "../Product";

const AlbumsMatrix = ({
  creations,
  inputs,
  toggleLikeStatus,
  regenerateCreation,
  settings,
  artifact,
}) => {
  const { openModal } = useModalPreview();

  const handleRegenerateClick = (x, y) => {
    regenerateCreation(x, y);
  };
  const renderMatrix = () => {
    const renderContent = creations.map((creation, index) => {
      const x = Math.floor(index / 3); // Calculate row index (x)
      const y = index % 3; // Calculate column index (y)

      return (
        <div
          key={index}
          className={settings.itemStyle}
          style={settings.gridArea ? { gridArea: `item${index + 1}` } : {}}
        >
          <div
            style={{
              position: "relative",
              height: settings.height,
              width: settings.width,
              borderRadius: settings.borderRadius || "0px",
            }}
          >
            <CreationCard
              // className={styles.product}
              item={creation}
              isAlbum={true}
              settings={settings}
              key={index}
              onShowPreview={
                creation ? () => openModal(creations, index, artifact) : undefined
              }
              onLikeDislike={
                creation
                  ? () =>
                      toggleLikeStatus(
                        creation.creation_id,
                        creation.creation_sub_id
                      )
                  : undefined
              }
              onRegenerateClick={
                creation ? () => handleRegenerateClick(x, y) : undefined
              }
              showPrompt={false}
            />
            <img
              src={settings.frameImage}
              height={settings.height + settings.framePadding}
              width={settings.width + settings.framePadding}
              style={{
                position: "absolute",
                top: settings.frameTop,
                left: settings.frameLeft,
                zIndex: 100,
                pointerEvents: "none",
                zIndex: 4,
              }}
            />
            <div
              style={{
                position: "absolute",
                bottom: settings.promptTextBottom,
                width: "100%",
                textAlign: "center",
                color: "white",
                fontSize: settings.fontSize,
                zIndex: 100,
              }}
            >
              {/* {inputs[index]?.substring(0, 12)} */}
              {Object.keys(inputs)[index]?.substring(0, 20)}
              {/* {creations[index].raw_prompt?.substring(0, 20)} */}
              {/* {index} */}
            </div>
          </div>
        </div>
      );
    });
    console.log('creations', creations);

    const containerStyle = settings.containerStyle;
    const backgroundImage = settings.backgroundImage
      ? { backgroundImage: `url(${settings.backgroundImage})` }
      : {};

    return (
      <div style={{ ...containerStyle, ...backgroundImage }}>
        {renderContent}
      </div>
    );
  };

  return renderMatrix();
};

export default AlbumsMatrix;
