import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useLocation } from "react-router-dom";
import { createPortal } from "react-dom";
import ApiService from "../../services/ApiService";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./ModalProduct.module.sass";
import Panel from "./Panel";
import Slider from "react-slick";
import Icon from "../Icon";
import Loader from "../Loader";
import { DownloadService } from "../../services/DownloadService";
import { formatTimestampToLocalTime } from "../../utils";
import {
  TripleRollingOfferOption,
  PersonalOffer6Option,
} from "../../screens/GenerateOfferScreen";
import PromptInput from "../PromptBox/PromptInput";
import cn from "classnames";

import { toast } from "react-hot-toast";
import {
  CheckIsUserLimited,
  updateGenerationAttempts,
} from "../../components/PromptBox/rateLimit";
import GenerationService from "../../services/GenerationService";
import { useNavigate } from "react-router-dom";
import Card from "../Card";
import CustomTooltip from "../CustomTooltip";
import TooltipData from "../CustomTooltip/TooltipsData";
import { useAuth } from "../../contexts/AuthContext";
import Painter from "../Painter";
import { REFERENCE_TYPES } from "../../consts";
import { set } from "date-fns";

const PROMPT_MIN_LENGTH = 3;

const SIMPLE_CARD_ARTIFACT_ID = "21ec4626-45d7-462e-b2e9-761cd6910b35";
const COMPLEX_CARD_ARTIFACT_ID = "d4d67f9f-6b94-4845-a8cc-b4a523a25827";

const COMMUNIX_ORGANIZATION_ID = "COMMUNIX-418be7";
const HALFBITE_ORGANIZATION_ID = "HALFBITE-1h800v";

const WatermarkedImage = forwardRef(
  (
    {
      src,
      watermarkSrc,
      editMode,
      eraseMode,
      paintOverMode,
      magicCutMode,
      onMaskCanvasUpdate,
      creation,
      brushColor,
      setBrushColor,
      pickedColor,
      handleMagicCutClick,
      onDimensionsChange,
    },
    ref
  ) => {
    const canvasRef = useRef(null);
    const maskCanvasRef = useRef(null);

    const [isPainting, setIsPainting] = useState(false);
    const [mousePosition, setMousePosition] = useState(undefined);
    const [isClick, setIsClick] = useState(false);
    const [originalImageDimensions, setOriginalImageDimensions] = useState({
      width: 0,
      height: 0,
    });
    const [brushSize, setBrushSize] = useState(20);

    useEffect(() => {
      const preventContextMenu = (event) => {
        event.preventDefault();
      };

      window.addEventListener("contextmenu", preventContextMenu);

      return () => {
        window.removeEventListener("contextmenu", preventContextMenu);
      };
    }, []);

    useEffect(() => {
      const image = new Image();
      const watermark = new Image();
      image.src = src;

      watermark.src = watermarkSrc;

      image.onload = () => {
        setOriginalImageDimensions({
          width: image.width,
          height: image.height,
        });
        onDimensionsChange({ width: image.width, height: image.height }); // Pass dimensions up to parent

        watermark.onload = () => {
          const maxWidth = window.innerWidth * 0.66;
          const maxHeight = window.innerHeight * 0.6;

          let canvasWidth = image.width;
          let canvasHeight = image.height;

          if (canvasWidth > maxWidth) {
            canvasHeight = (maxWidth / canvasWidth) * canvasHeight;
            canvasWidth = maxWidth;
          }
          if (canvasHeight > maxHeight) {
            canvasWidth = (maxHeight / canvasHeight) * canvasWidth;
            canvasHeight = maxHeight;
          }

          try {
            const canvas = canvasRef.current;

            const ctx = canvas.getContext("2d");
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
            ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);
            if (!editMode && !eraseMode && !paintOverMode && !magicCutMode) {
              const watermarkWidth =
                watermark.width * (canvasWidth / image.width);
              const watermarkHeight =
                watermark.height * (canvasHeight / image.height);
              const x = canvasWidth - watermarkWidth - 10;
              const y = canvasHeight - watermarkHeight - 10;

              ctx.globalAlpha = 0.5;
              ctx.drawImage(watermark, x, y, watermarkWidth, watermarkHeight);
            } else {
              ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);
            }
          } catch (e) {}

          try {
            const maskCanvas = maskCanvasRef.current;
            if (!maskCanvas) return;
            maskCanvas.width = canvasWidth;
            maskCanvas.height = canvasHeight;

            // Set opacity based on the mode
            if (editMode || eraseMode) {
              maskCanvas.style.opacity = 0.5;
            } else if (paintOverMode) {
              maskCanvas.style.opacity = 1;
            }
          } catch (e) {
            console.error("Mask Canvas Error: " + e);
          }
        };
      };
    }, [src, watermarkSrc, editMode, eraseMode, paintOverMode, magicCutMode]);

    useEffect(() => {
      if (maskCanvasRef.current && (editMode || eraseMode || paintOverMode)) {
        const svgBrushColor = encodeURIComponent(brushColor);

        maskCanvasRef.current.style.cursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${brushSize}" height="${brushSize}" viewBox="0 0 ${brushSize} ${brushSize}"><circle cx="${
          brushSize / 2
        }" cy="${brushSize / 2}" r="${
          brushSize / 2
        }" fill="${svgBrushColor}" /></svg>') ${brushSize / 2} ${
          brushSize / 2
        }, auto`;
      }
    }, [brushSize, brushColor, editMode, eraseMode, paintOverMode]);

    useEffect(() => {
      const handleScroll = (event) => {
        setBrushSize((prevBrushSize) => {
          let newBrushSize = prevBrushSize + (event.deltaY < 0 ? 5 : -5);
          if (newBrushSize < 1) newBrushSize = 1;
          if (newBrushSize > 100) newBrushSize = 100;
          return newBrushSize;
        });
      };

      window.addEventListener("wheel", handleScroll);
      return () => {
        window.removeEventListener("wheel", handleScroll);
      };
    }, []);

    useEffect(() => {
      if (maskCanvasRef.current && !magicCutMode) {
        // Convert brushColor to an SVG-friendly format, replacing special characters
        const svgBrushColor = encodeURIComponent(brushColor);

        // Update cursor with dynamic size and color
        maskCanvasRef.current.style.cursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${brushSize}" height="${brushSize}" viewBox="0 0 ${brushSize} ${brushSize}"><circle cx="${
          brushSize / 2
        }" cy="${brushSize / 2}" r="${
          brushSize / 2
        }" fill="${svgBrushColor}" /></svg>') ${brushSize / 2} ${
          brushSize / 2
        }, auto`;
      }
    }, [brushSize, brushColor, magicCutMode]);

    const getCoordinates = (event) => {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      return {
        x: (event.clientX - rect.left) * (canvas.width / rect.width),
        y: (event.clientY - rect.top) * (canvas.height / rect.height),
      };
    };

    const startPainting = (event) => {
      console.log("start painting");
      if (!editMode && !eraseMode && !paintOverMode) return;

      if (event.button === 2) {
        // Right-click
        setBrushColor("erase"); // Set to a special 'erase' mode
      } else {
        if (paintOverMode) {
          setBrushColor(pickedColor);
        } else {
          // Left-click
          setBrushColor("paint"); // Set to paint mode
        }
      }

      const coordinates = getCoordinates(event);
      if (coordinates) {
        setIsPainting(true);
        setMousePosition(coordinates);
        setIsClick(true);
      }
    };

    const paint = (event) => {
      if (!editMode && !eraseMode && !paintOverMode) return;
      if (isPainting) {
        setIsClick(false);
        const newMousePosition = getCoordinates(event);
        drawLine(mousePosition, newMousePosition);
        setMousePosition(newMousePosition);
      }
    };

    const endPainting = () => {
      if (!editMode && !eraseMode && !paintOverMode) return;
      if (isPainting) {
        setIsPainting(false);
      }
      setMousePosition(undefined);

      let maskCanvas = maskCanvasRef.current;
      if (onMaskCanvasUpdate) {
        const clonedCanvas = cloneCanvas(maskCanvas);
        if (paintOverMode) {
          console.log("paint over mode");
          const resizedCanvas = resizeCanvas(
            maskCanvas,
            originalImageDimensions
          );
          const imageDataResized = getCanvasImageData(resizedCanvas);
          const allWhitePixelsConnected = checkWhitePixelsConnectivity(
            imageDataResized.data,
            resizedCanvas.width,
            resizedCanvas.height
          );
          onMaskCanvasUpdate(
            allWhitePixelsConnected || paintOverMode
              ? resizedCanvas.toDataURL()
              : null
          );
        } else {
          processCanvasImage(clonedCanvas, (processedCanvas) => {
            // if (paintOverMode){
            //   console.log("paint over mode");
            //   processedCanvas = clonedCanvas}
            const resizedCanvas = resizeCanvas(
              processedCanvas,
              originalImageDimensions
            );
            const imageDataResized = getCanvasImageData(resizedCanvas);
            const allWhitePixelsConnected = checkWhitePixelsConnectivity(
              imageDataResized.data,
              resizedCanvas.width,
              resizedCanvas.height
            );
            onMaskCanvasUpdate(
              allWhitePixelsConnected || paintOverMode
                ? resizedCanvas.toDataURL()
                : null
            );
          });
        }
      }
    };

    const cloneCanvas = (canvas) => {
      const clonedCanvas = document.createElement("canvas");
      clonedCanvas.width = canvas.width;
      clonedCanvas.height = canvas.height;
      const clonedCtx = clonedCanvas.getContext("2d");
      clonedCtx.drawImage(canvas, 0, 0);
      return clonedCanvas;
    };

    const processCanvasImage = (canvas, callback) => {
      const ctx = canvas.getContext("2d");
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        if (
          data[i] === 0 &&
          data[i + 1] === 0 &&
          data[i + 2] === 0 &&
          data[i + 3] !== 0
        ) {
          data[i] = 255; // Red
          data[i + 1] = 255; // Green
          data[i + 2] = 255; // Blue
        } else {
          data[i] = 0; // Red
          data[i + 1] = 0; // Green
          data[i + 2] = 0; // Blue
          data[i + 3] = 255; // Alpha
        }
      }

      ctx.putImageData(imageData, 0, 0);
      callback(canvas);
    };

    const resizeCanvas = (canvas, dimensions) => {
      const resizedCanvas = document.createElement("canvas");
      resizedCanvas.width = dimensions.width;
      resizedCanvas.height = dimensions.height;
      const resizedCtx = resizedCanvas.getContext("2d");
      resizedCtx.drawImage(canvas, 0, 0, dimensions.width, dimensions.height);
      return resizedCanvas;
    };

    const getCanvasImageData = (canvas) => {
      const ctx = canvas.getContext("2d");
      return ctx.getImageData(0, 0, canvas.width, canvas.height);
    };

    const checkWhitePixelsConnectivity = (data, width, height) => {
      const visited = new Array(width * height).fill(false);
      const directions = [
        [-1, 0],
        [1, 0],
        [0, -1],
        [0, 1], // Up, Down, Left, Right
      ];

      const isWhitePixel = (x, y) => {
        const index = (y * width + x) * 4;
        return (
          data[index] === 255 &&
          data[index + 1] === 255 &&
          data[index + 2] === 255
        );
      };

      const floodFill = (x, y) => {
        const stack = [[x, y]];
        visited[y * width + x] = true;
        let count = 0;

        while (stack.length > 0) {
          const [cx, cy] = stack.pop();
          count++;
          for (const [dx, dy] of directions) {
            const nx = cx + dx,
              ny = cy + dy;
            if (nx >= 0 && nx < width && ny >= 0 && ny < height) {
              const index = ny * width + nx;
              if (!visited[index] && isWhitePixel(nx, ny)) {
                visited[index] = true;
                stack.push([nx, ny]);
              }
            }
          }
        }

        return count;
      };

      let startX = -1,
        startY = -1;
      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          if (isWhitePixel(x, y)) {
            startX = x;
            startY = y;
            break;
          }
        }
        if (startX !== -1) break;
      }

      if (startX === -1) return false; // No white pixel found

      const whitePixelCount = floodFill(startX, startY);

      let totalWhitePixels = 0;
      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          if (isWhitePixel(x, y)) totalWhitePixels++;
        }
      }

      return whitePixelCount === totalWhitePixels;
    };

    const drawLine = (originalMousePosition, newMousePosition) => {
      const canvas = maskCanvasRef.current;
      const context = canvas.getContext("2d");

      if (context) {
        if (brushColor === "erase") {
          context.globalCompositeOperation = "destination-out"; // Switch to erase mode
          context.strokeStyle = "rgba(0,0,0,1)"; // The actual color doesn't matter in erase mode
        } else {
          if (brushColor === "paint") {
            context.globalCompositeOperation = "source-over"; // Switch to paint mode
            context.strokeStyle = "rgba(0, 0, 0, 1)"; // Set the brush color
          } else {
            context.globalCompositeOperation = "source-over"; // Switch to paint mode
            context.strokeStyle = brushColor; // Set the brush color
          }
        }

        context.lineJoin = "round";
        context.lineWidth = brushSize;

        context.beginPath();
        context.moveTo(originalMousePosition.x, originalMousePosition.y);
        context.lineTo(newMousePosition.x, newMousePosition.y);
        context.closePath();

        context.stroke();
      } else {
        toast.error("Failed to get context for mask canvas.");
      }
    };

    const clearCanvas = () => {
      const maskCanvas = maskCanvasRef.current;

      if (maskCanvas) {
        const context = maskCanvas.getContext("2d");
        if (context) {
          context.clearRect(0, 0, maskCanvas.width, maskCanvas.height);
        }
      }
    };

    useImperativeHandle(ref, () => ({
      clearCanvas,
      drawMaskOnCanvas(blob) {
        const maskCanvas = maskCanvasRef.current;
        if (!maskCanvas) return;

        const context = maskCanvas.getContext("2d");

        const maskImage = new Image();
        const url = URL.createObjectURL(blob);
        maskImage.onload = () => {
          context.clearRect(0, 0, maskCanvas.width, maskCanvas.height);
          context.drawImage(
            maskImage,
            0,
            0,
            maskCanvas.width,
            maskCanvas.height
          );
          URL.revokeObjectURL(url);
        };

        maskImage.src = url;
      },
      getCoordinates, // Expose getCoordinates to ref
      maskCanvasRef,
    }));

    return (
      <>
        <canvas
          id="mainCanvas"
          ref={canvasRef}
          style={{
            borderRadius: "16px",
            height: creation.creation_type === "ui_component" ? 150 : "",
          }}
        />
        {(editMode || eraseMode || paintOverMode) && (
          <canvas
            ref={maskCanvasRef}
            id="maskCanvas"
            onMouseDown={startPainting}
            onMouseMove={paint}
            onMouseUp={endPainting}
            onMouseLeave={endPainting}
            style={{
              position: "absolute",
              borderRadius: "16px",
              cursor:
                editMode || eraseMode || paintOverMode
                  ? "crosshair"
                  : "default",
              top: 0,
              height: creation.creation_type === "ui_component" ? 150 : "",
            }}
          />
        )}
        {magicCutMode && (
          <canvas
            ref={maskCanvasRef}
            id="maskCanvas"
            onClick={(event) => handleMagicCutClick(event)} // Pass the event here
            style={{
              position: "absolute",
              borderRadius: "16px",
              cursor: "default",
              top: 0,
              height: creation.creation_type === "ui_component" ? 150 : "",
            }}
          />
        )}
      </>
    );
  }
);

const SlickArrow = ({ children, ...props }) => (
  <button {...props}>{children}</button>
);

const ModalProduct = ({
  visible,
  onClose,
  onGenerate,
  gallery,
  download,
  artifact,
  items,
  currentViewingItemIndex,
  fetchArtifactAndProducts,
}) => {
  const [editModeEnabled, setIsEditModeEnabled] = useState(false);
  const [eraseModeEnabled, setIsEraseModeEnabled] = useState(false);
  const [paintOverModeEnabled, setIsPaintOverModeEnabled] = useState(false);
  const [magicCutModeEnabled, setIsMagicCutModeEnabled] = useState(false);
  const [editModePrompt, setEditModePrompt] = useState("");

  const [embeddingData, setEmbeddingData] = useState(null);
  const [inputPointsList, setInputPointsList] = useState([]);
  const [inputLabelsList, setInputLabelsList] = useState([]);

  const [brushColor, setBrushColor] = useState("rgba(0, 0, 0, 1)");
  const [pickedColor, setPickedColor] = useState("");

  const watermarkedImageRef = useRef(); // Add ref for WatermarkedImage
  const [maskCanvasDataUrl, setMaskCanvasDataUrl] = useState(null);
  const [currentCreation, setCurrentCreation] = useState(null);
  const [showNextArrow, setShowNextArrow] = useState(true);
  const [showPrevArrow, setShowPrevArrow] = useState(true);
  const { artifacts } = useAuth();

  const location = useLocation();
  const isArtifactPage = /\/artifacts\/[a-f0-9-]+/.test(location.pathname);
  const isGalleryPage = /\/gallery\//.test(location.pathname);

  const [isUpscaleable, setIsUpscaleable] = useState(false);

  const handleGetImageEmbeddings = () => {
    return ApiService.post("/magic_cut/get_image_embeddings", {
      image_url: currentCreation.result_image_url[0],
      creation_id: currentCreation.creation_id,
      creation_sub_id: currentCreation.creation_sub_id,
    });
  };
  const handleGetMasks = (newPointsList, newLabelsList) => {
    return ApiService.post(
      "/magic_cut/get_masks",
      {
        // embedding: embeddingData,
        creation_id: currentCreation.creation_id,
        creation_sub_id: currentCreation.creation_sub_id,
        input_points_list: newPointsList,
        input_labels_list: newLabelsList,
      },
      "blob"
    )
      .then((maskResponse) => {
        // console.log("Received mask response:", maskResponse);

        if (!(maskResponse instanceof Blob)) {
          throw new Error(
            "Expected a Blob response but received something else."
          );
        }

        if (watermarkedImageRef.current) {
          // Pass the blob to drawMaskOnCanvas
          watermarkedImageRef.current.drawMaskOnCanvas(maskResponse);
        }
      })
      .catch((error) => {
        console.error("Failed to retrieve masks:", error);
        toast.error(`Failed to retrieve masks: ${error.message}`);
      });
  };

  const pixelsLimitForUpscaling = 1890625;
  useEffect(() => {
    if (
      currentCreation &&
      currentCreation.result_image_url &&
      currentCreation.result_image_url[0]
    ) {
      checkImageSize(currentCreation.result_image_url[0])
        .then(({ width, height }) => {
          if (width * height < pixelsLimitForUpscaling) {
            setIsUpscaleable(true);
          } else {
            setIsUpscaleable(false);
          }
        })
        .catch((err) => {
          console.error("Error fetching image size:", err);
        });
    }
  }, [currentCreation]);

  function checkImageSize(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = function () {
        const width = img.width;
        const height = img.height;
        resolve({ width, height });
      };

      img.onerror = function () {
        reject(new Error("Could not load image at " + url));
      };

      img.src = url;
    });
  }

  const [availableOperations, setAvailableOperation] = useState({});
  useEffect(() => {
    if (artifact) {
      setAvailableOperation(artifact.available_operations);
    }
  }, [artifact]);

  useEffect(() => {
    setShowNextArrow(!editModeEnabled);
    setShowPrevArrow(!editModeEnabled);
  }, [editModeEnabled]);

  useEffect(() => {
    setShowNextArrow(!eraseModeEnabled);
    setShowPrevArrow(!eraseModeEnabled);
  }, [eraseModeEnabled]);

  useEffect(() => {
    setShowNextArrow(!paintOverModeEnabled);
    setShowPrevArrow(!paintOverModeEnabled);
  }, [paintOverModeEnabled]);

  useEffect(() => {
    setShowNextArrow(!magicCutModeEnabled);
    setShowPrevArrow(!magicCutModeEnabled);
  }, [magicCutModeEnabled]);

  const handleEditMode = () => {
    setBrushColor("rgba(0, 0, 0, 1)");
    setIsEditModeEnabled(!editModeEnabled);
    setIsEraseModeEnabled(false);
    setIsPaintOverModeEnabled(false);
    setIsMagicCutModeEnabled(false);
  };

  const handleEraseMode = () => {
    setBrushColor("rgba(0, 0, 0, 1)");
    setIsEraseModeEnabled(!eraseModeEnabled);
    setIsEditModeEnabled(false);
    setIsPaintOverModeEnabled(false);
    setIsMagicCutModeEnabled(false);
  };

  const handlePaintOverMode = () => {
    setBrushColor("rgba(0, 0, 0, 1)");
    setIsPaintOverModeEnabled(!paintOverModeEnabled);
    setIsEditModeEnabled(false);
    setIsEraseModeEnabled(false);
    setIsMagicCutModeEnabled(false);
  };

  const handleMagicCutMode = () => {
    setIsMagicCutModeEnabled(!magicCutModeEnabled);
    setEmbeddingData(null);
    setInputPointsList([]);
    setInputLabelsList([]);
    setIsEditModeEnabled(false);
    setIsEraseModeEnabled(false);
    setIsPaintOverModeEnabled(false);
  };

  const handleClearPoints = () => {
    setInputPointsList([]);
    setInputLabelsList([]);
    // handleGetMasks([], []);
    clearDrawing();
  };

  const clearDrawing = () => {
    if (watermarkedImageRef.current) {
      watermarkedImageRef.current.clearCanvas();
    }
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const updateArrowVisibility = (index) => {
    setShowNextArrow(isNextImageCompleted(index));
    setShowPrevArrow(isPreviousImageCompleted(index));
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (currentViewingItemIndex !== null && items) {
      setCurrentCreation(items[currentViewingItemIndex]);
    }
  }, [currentViewingItemIndex, items]);

  const validateInputThenGenerateCreations = () => {
    if (CheckIsUserLimited()) {
      return;
    }
    if (editModePrompt.length < PROMPT_MIN_LENGTH) {
      toast.error(
        `Prompt must be at least ${PROMPT_MIN_LENGTH} characters long.`
      );
      return;
    }
    if (!maskCanvasDataUrl) {
      toast.error("Please select only one connected area to edit");
      return;
    }

    const currentImageUrl = items[currentViewingItemIndex].result_image_url[0];

    if (maskCanvasDataUrl) {
      fetch(maskCanvasDataUrl)
        .then((res) => res.blob())
        .then((maskImageBlob) => {
          const currentItem = items[currentViewingItemIndex];
          const prompt = editModePrompt;
          updateGenerationAttempts();
          setIsEditModeEnabled(false);
          setIsEraseModeEnabled(false);
          setIsPaintOverModeEnabled(false);
          setIsMagicCutModeEnabled(false);
          setEditModePrompt("");
          toast
            .promise(
              GenerationService.startGeneration({
                prompt: prompt,
                numberOfImages: 4,
                isRemoveBackground: false,
                enrichPromptByChatGPTFlag: false,
                creationId: null,
                image: currentImageUrl,
                referenceType: REFERENCE_TYPES.INPAINTING,
                artifactId: currentItem.artifact_id,
                maskImage: maskImageBlob,
              }),
              {
                loading: "Starting generation...",
                success: "Generation Started Successfully!",
                error: "Generation Failed!",
              }
            )
            .then((response) => {
              if (response) {
                console.log(response);
                onGenerate();
                onClose();
              }
            })
            .catch((error) => {
              console.error("Generation error:", error);
            });
        })
        .catch((error) => {
          console.error("Failed to create blob from data URL:", error);
          toast.error("Failed to create blob from data URL.");
        });
    } else {
      toast.error("Mask Canvas is empty.");
    }
  };

  const eraseThenGenerateCreations = () => {
    console.log("erase inpainting");
    if (CheckIsUserLimited()) {
      return;
    }
    if (!maskCanvasDataUrl) {
      toast.error("Please select only one connected area to edit");
      return;
    }

    const currentImageUrl = items[currentViewingItemIndex].result_image_url[0];

    if (maskCanvasDataUrl) {
      fetch(maskCanvasDataUrl)
        .then((res) => res.blob())
        .then((maskImageBlob) => {
          const currentItem = items[currentViewingItemIndex];
          const prompt = editModePrompt;
          updateGenerationAttempts();
          setEditModePrompt("");
          toast
            .promise(
              GenerationService.startGeneration({
                prompt: currentItem.prompt,
                numberOfImages: 4,
                isRemoveBackground: false,
                enrichPromptByChatGPTFlag: false,
                creationId: null,
                image: currentImageUrl,
                referenceType: "erase inpainting",
                artifactId: currentItem.artifact_id,
                maskImage: maskImageBlob,
              }),
              {
                loading: "Starting generation...",
                success: "Generation Started Successfully!",
                error: "Generation Failed!",
              }
            )
            .then((response) => {
              if (response) {
                console.log(response);
                onGenerate();
                onClose();
              }
            })
            .catch((error) => {
              console.error("Generation error:", error);
            });
        })
        .catch((error) => {
          console.error("Failed to create blob from data URL:", error);
          toast.error("Failed to create blob from data URL.");
        });
    } else {
      toast.error("Mask Canvas is empty.");
    }
  };

  const handleDownloadSegmentedArea = () => {
    if (
      !currentCreation ||
      !embeddingData ||
      inputPointsList.length === 0 ||
      inputLabelsList.length === 0
    ) {
      toast.error(
        "Please ensure an image and points are selected before downloading."
      );
      return;
    }

    toast.promise(
      ApiService.post(
        "/magic_cut/download",
        {
          image_url: currentCreation.result_image_url[0],
          input_points_list: inputPointsList,
          input_labels_list: inputLabelsList,
          creation_id: currentCreation.creation_id,
          creation_sub_id: currentCreation.creation_sub_id,
        },
        "blob"
      ).then((response) => {
        if (!(response instanceof Blob)) {
          throw new Error(
            "Expected a Blob response but received something else."
          );
        }

        // Create a URL for the blob and download the file
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "segmented_area.png"); // Specify the download filename
        document.body.appendChild(link);
        link.click();
        link.remove();
      }),
      {
        loading: "Downloading...",
        success: "Download successfully!",
        error: "Failed to download segmented area.",
      }
    );
  };

  const paintOverThenGenerateCreations = () => {
    console.log("paint over....");
    if (CheckIsUserLimited()) {
      return;
    }
    if (!maskCanvasDataUrl) {
      toast.error("Please select only one connected area to edit");
      return;
    }

    const currentImageUrl = items[currentViewingItemIndex].result_image_url[0];

    if (maskCanvasDataUrl) {
      fetch(maskCanvasDataUrl)
        .then((res) => res.blob())
        .then((maskImageBlob) => {
          const currentItem = items[currentViewingItemIndex];
          const prompt = editModePrompt;
          updateGenerationAttempts();
          setEditModePrompt("");
          toast
            .promise(
              GenerationService.startGeneration({
                prompt: currentItem.prompt,
                numberOfImages: 4,
                isRemoveBackground: false,
                enrichPromptByChatGPTFlag: false,
                creationId: null,
                image: currentImageUrl,
                referenceType: "paint over",
                artifactId: currentItem.artifact_id,
                maskImage: maskImageBlob,
              }),
              {
                loading: "Starting generation...",
                success: "Generation Started Successfully!",
                error: "Generation Failed!",
              }
            )
            .then((response) => {
              if (response) {
                console.log(response);
                onGenerate();
                onClose();
              }
            })
            .catch((error) => {
              console.error("Generation error:", error);
            });
        })
        .catch((error) => {
          console.error("Failed to create blob from data URL:", error);
          toast.error("Failed to create blob from data URL.");
        });
    } else {
      toast.error("Mask Canvas is empty.");
    }
  };

  const sliderRef = useRef();
  const onKeyDownFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );
  useEffect(() => {
    if (sliderRef.current && sliderRef.current.slickGoTo) {
      sliderRef.current.slickGoTo(currentViewingItemIndex, true);
    }
  }, [currentViewingItemIndex]);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDownFunction, false);
    return () => {
      document.removeEventListener("keydown", onKeyDownFunction, false);
    };
  }, [onKeyDownFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal-product");
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  const handleGenerateTripleOffer = async () => {
    const creation = currentCreation;
    onClose();

    const requestData = await GenerationService.GenerateTripleRollingOffer(
      creation
    );
    navigate("/triple-rolling-offer");
  };

  const handleGeneratePersonalOffer6 = async () => {
    const creation = currentCreation;
    onClose();
    const requestData = await GenerationService.GeneratePersonalOffer6(
      creation
    );
    navigate("/personal-offer-6");
  };

  const settings = {
    lazyLoad: "ondemand",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    arrows:
      !editModeEnabled &&
      !eraseModeEnabled &&
      !paintOverModeEnabled &&
      !magicCutModeEnabled,
    nextArrow:
      !editModeEnabled &&
      !eraseModeEnabled &&
      !paintOverModeEnabled &&
      !magicCutModeEnabled &&
      showNextArrow ? (
        <div>
          <SlickArrow>
            <Icon name="arrow-right" size="40" />
          </SlickArrow>
        </div>
      ) : null,
    prevArrow:
      !editModeEnabled &&
      !eraseModeEnabled &&
      !paintOverModeEnabled &&
      !magicCutModeEnabled &&
      showPrevArrow ? (
        <SlickArrow>
          <Icon name="arrow-left" size="40" />
        </SlickArrow>
      ) : null,
    beforeChange: (oldIndex, newIndex) => {
      if (items[newIndex].status === "completed") {
        setCurrentCreation(items[newIndex]);
        updateArrowVisibility(newIndex);
        setIsEditModeEnabled(false);
        setIsEraseModeEnabled(false);
        setIsPaintOverModeEnabled(false);
        setIsMagicCutModeEnabled(false);
      } else {
        toast.error("Please wait for the image to be generated");
        onClose();
      }
    },
  };

  const isNextImageCompleted = (index) => {
    const nextIndex = index + 1;
    return nextIndex < items.length && items[nextIndex].status === "completed";
  };

  const isPreviousImageCompleted = (index) => {
    const prevIndex = index - 1;
    return prevIndex >= 0 && items[prevIndex].status === "completed";
  };

  const handleGenerateDecoy = () => {
    GenerationService.GenerateDecoy(currentCreation, navigate);
    onClose();
  };
  const toggleLikeStatus = (creation) => {
    const updatedStatus = !creation.liked;

    toast
      .promise(
        ApiService.post(
          `/like_or_dislike_creation/${creation.creation_id}/${creation.creation_sub_id}`,
          { liked: updatedStatus }
        ),
        {
          loading: "Updating like status...",
          success: () => {
            setCurrentCreation((prev) => ({ ...prev, liked: updatedStatus }));
            return updatedStatus
              ? "Liked successfully!"
              : "Unliked successfully!";
          },
          error: "Failed to update like status. Please try again later.",
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const displayName = () => {
    const currentArtifact = artifacts.find(
      (artifact) => artifact.artifact_id === currentCreation?.artifact_id
    );
    if (currentArtifact?.display_name) {
      return currentArtifact.display_name;
    }
    if (currentCreation?.artifact_id === SIMPLE_CARD_ARTIFACT_ID) {
      return "Simple Card";
    }
    if (currentCreation?.artifact_id === COMPLEX_CARD_ARTIFACT_ID) {
      return "Complex Card";
    }
    return "N/A";
  };
  const handleMagicCutClick = (event) => {
    if (
      watermarkedImageRef.current &&
      watermarkedImageRef.current.maskCanvasRef.current
    ) {
      const coordinates = watermarkedImageRef.current.getCoordinates(event);

      console.log("Coordinates in ModalProduct:", coordinates);
      console.log(
        "Original Image Dimensions:",
        originalImageDimensions.width,
        originalImageDimensions.height
      );

      if (
        coordinates &&
        coordinates.x !== undefined &&
        coordinates.y !== undefined
      ) {
        const newPointsList = [
          ...inputPointsList,
          [
            coordinates.x *
              (originalImageDimensions.width /
                watermarkedImageRef.current.maskCanvasRef.current.width),
            coordinates.y *
              (originalImageDimensions.height /
                watermarkedImageRef.current.maskCanvasRef.current.height),
          ],
        ];

        const newLabelsList = [...inputLabelsList, 1];

        setInputPointsList(newPointsList);
        setInputLabelsList(newLabelsList);

        toast.promise(
          handleGetMasks(newPointsList, newLabelsList), // Assuming this returns a promise
          {
            loading: "Processing...",
            success: "Done!",
            error: "Failed to apply mask.",
          }
        );
      }
    } else {
      console.error("maskCanvasRef or watermarkedImageRef is undefined");
    }
  };

  const [originalImageDimensions, setOriginalImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const handleDimensionsChange = (dimensions) => {
    setOriginalImageDimensions(dimensions);
  };

  return createPortal(
    visible && (
      <div id="modal-product" className={styles.modal}>
        <div className={styles.outer}>
          <div className={styles.control}>
            <button className={styles.close} onClick={onClose}>
              <Icon name="close" size="20" />
            </button>
          </div>
          <>
              {(editModeEnabled ||
                paintOverModeEnabled ||
                eraseModeEnabled) && (
                <div
                    style={{
                      position: "absolute",
                      width: 330,
                      right: "3vw",
                      left: "3vw",
                      height: 400,
                    }}
                >
                <Card>
                  <h4 style={{ marginBottom: 15 }}>
                    <img
                      src="/images/scroll_up_icon.webp"
                      alt="Scroll Up Icon"
                      width={30}
                      height={30}
                      style={{ marginTop: -8, marginLeft: -4, marginRight: 0 }}
                    />
                    Scroll Up - Increase Brush Size
                  </h4>
                  <h4 style={{ marginBottom: 15 }}>
                    <img
                      src="/images/scroll_down_icon.png"
                      alt="Scroll Down Icon"
                      width={30}
                      height={30}
                      style={{ marginTop: -8, marginLeft: -4, marginRight: 0 }}
                    />
                    Scroll Down - Decrease Brush Size
                  </h4>
                  <h4>
                    <img
                      src="/images/right_click.png"
                      alt="Scroll Down Icon"
                      width={26}
                      height={26}
                      style={{ marginTop: -8, marginLeft: -4, marginRight: 0 }}
                    />
                    Right Click - Remove Drawing
                  </h4>
                </Card>
            </div>
              )}
            <div
              style={{
                position: "absolute",
                right: 40,
                width: 300,
                height: 400,
              }}
            >
              <Card>
                <h4>
                  <img
                    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath d='M8 12h8m-8 4h4m4.5 2.5l-1.5-1.5H5A2.5 2.5 0 0 1 2.5 13V5A2.5 2.5 0 0 1 5 2.5h14A2.5 2.5 0 0 1 21.5 5v8A2.5 2.5 0 0 1 19 15.5h-6.5l-3 3Z' /%3E%3C/svg%3E"
                    alt="Prompt Icon"
                    width={24}
                    height={24}
                    style={{ marginRight: 8 }}
                  />
                  <i>{currentCreation?.prompt}</i>
                </h4>
                <br></br>
                <p>
                  <img
                    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath d='M16 2H8a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM14 2v4H8V2h6zM8 8h8v12H8V8z' /%3E%3C/svg%3E"
                    alt="Type Icon"
                    width={24}
                    height={24}
                    style={{ marginRight: 8 }}
                  />
                  {displayName()}
                </p>
                <p>
                  <img
                    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath d='M12 14c-3.315 0-6 2.239-6 5v1h12v-1c0-2.761-2.685-5-6-5zM12 4a4 4 0 1 1 0 8 4 4 0 0 1 0-8z' /%3E%3C/svg%3E"
                    alt="Creator Icon"
                    width={24}
                    height={24}
                    style={{ marginRight: 8 }}
                  />
                  {currentCreation?.creator_name}
                </p>
                <p>
                  <img
                    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath d='M3 6h18M3 10h18M7 2v4m10-4v4M5 20h14a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2z' /%3E%3C/svg%3E"
                    alt="Date Icon"
                    width={24}
                    height={24}
                    style={{ marginRight: 8 }}
                  />
                  {formatTimestampToLocalTime(
                    currentCreation?.timestamp_creation_id?.split("_")[0]
                  )}
                </p>
                {currentCreation?.master_color && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        marginRight: "10px",
                        marginBottom: "0",
                      }}
                    >
                      Master Color
                    </p>
                    <CustomTooltip
                      title={currentCreation?.master_color}
                      placement="right"
                    >
                      <div
                        onClick={() => {
                          navigator.clipboard.writeText(
                            currentCreation?.master_color
                          );
                          toast.success("Hex value copied!");
                        }}
                        style={{
                          backgroundColor: `${currentCreation?.master_color}`,
                          borderRadius: "50%",
                          width: "37px",
                          height: "37px",
                        }}
                      />
                    </CustomTooltip>
                  </div>
                )}
              </Card>
              <br></br>

              {currentCreation?.sketch_image_url && (
                <Card>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginTop: -25,
                      marginBottom: 0,
                      padding: 2,
                    }}
                  >
                    {currentCreation.reference_type}
                  </p>
                  <img
                    src={currentCreation?.sketch_image_url}
                    style={{ width: "100%", height: "auto" }}
                  />
                </Card>
              )}
            </div>

            <div
              style={{
                position: "absolute",
                top: 40,
                left: "50%",
                transform: "translateX(-50%)",
                // backgroundColor: "red",
              }}
            >
              <div style={{ display: "flex", gap: 0.1 }}>
                {!editModeEnabled &&
                !eraseModeEnabled &&
                !paintOverModeEnabled &&
                !magicCutModeEnabled ? (
                  <>
                    <CustomTooltip
                      id="Like"
                      title={currentCreation?.liked ? "DisLike" : "Like"}
                      placement="top"
                    >
                      <button
                        className={cn(
                          "button-gray",
                          availableOperations?.like === false &&
                            styles.buttonDisabled
                        )}
                        onClick={() => toggleLikeStatus(currentCreation)}
                        data-testid="like"
                        disabled={availableOperations?.like === false}
                      >
                        {currentCreation?.liked ? (
                          <Icon name="heart-fill" fill="red" size="30" />
                        ) : (
                          <Icon name="heart" size="30" />
                        )}
                      </button>
                    </CustomTooltip>
                    <div style={{ position: "relative" }}>
                      <CustomTooltip
                        id="Download"
                        title={TooltipData["Download"]}
                        placement="top"
                      >
                        <button
                          // className="button-gray"
                          className={cn(
                            "button-gray",
                            availableOperations?.download_original === false &&
                              styles.buttonDisabled
                          )}
                          // onClick={toggleDropdown}
                          onClick={() => {
                            if (
                              availableOperations?.download_original !== false
                            ) {
                              toggleDropdown();
                            }
                          }}
                          data-testid="download"
                          disabled={
                            availableOperations?.download_original === false
                          }
                        >
                          <Icon name="download" size="30" />
                        </button>
                      </CustomTooltip>
                      {isDropdownOpen && (
                        <div
                          style={{
                            position: "absolute",
                            marginTop: "4px",
                            zIndex: 100000,
                          }}
                        >
                          <div style={{ display: "flex", gap: 0.5 }}>
                            <button
                              className={cn(
                                "button-gray",
                                availableOperations?.download_original ===
                                  false && styles.buttonDisabled
                              )}
                              onClick={() => {
                                setIsDropdownOpen(false);
                                toast
                                  .promise(
                                    DownloadService.DownloadCreation(
                                      currentCreation
                                    ),
                                    {
                                      loading: "Downloading image...",
                                      success: "Download successful!",
                                      error: "Download failed!",
                                    }
                                  )
                                  .then(() => {});
                              }}
                              data-testid="download"
                              disabled={
                                availableOperations?.download_original === false
                              }
                            >
                              Original
                            </button>
                            {
                              <button
                                // className={cn("button-gray",)}
                                className={cn(
                                  "button-gray",
                                  availableOperations?.download_upscale ===
                                    false && styles.buttonDisabled
                                )}
                                onClick={() => {
                                  setIsDropdownOpen(false);
                                  if (!isUpscaleable) {
                                    toast.error(
                                      "We're sorry, but the image size is too large for upscaling."
                                    );
                                  } else {
                                    toast
                                      .promise(
                                        DownloadService.DownloadCreationX2(
                                          currentCreation
                                        ),
                                        {
                                          loading: "Upscaling image...",
                                          success: "Download successful!",
                                          error: "Download failed!",
                                        }
                                      )
                                      .then(() => {});
                                  }
                                }}
                                data-testid="downloadx2"
                                disabled={
                                  availableOperations?.download_upscale ===
                                  false
                                }
                              >
                                X2
                              </button>
                            }
                          </div>
                        </div>
                      )}
                    </div>
                    {!isGalleryPage &&
                      currentCreation &&
                      (currentCreation.raw_prompt ||
                        currentCreation.prompt) && (
                        <>
                          <CustomTooltip
                            id="Variations"
                            title={TooltipData["Variations"]}
                            placement="top"
                          >
                            <button
                              className={cn(
                                "button-gray",
                                availableOperations?.variations === false &&
                                  styles.buttonDisabled
                              )}
                              disabled={
                                availableOperations?.variations === false
                              }
                              onClick={() => {
                                GenerationService.GenerateNewVariations(
                                  currentCreation
                                ).then(() => {
                                  onGenerate();
                                });
                                onClose();
                              }}
                              data-testid="variations"
                            >
                              <Icon
                                src="/images/svg/variations.png"
                                size="46"
                              />
                            </button>
                          </CustomTooltip>

                          <CustomTooltip
                            id="Regenerate"
                            title={TooltipData["Regenerate"]}
                            placement="top"
                          >
                            <button
                              className={cn(
                                "button-gray",
                                availableOperations?.regenerate === false &&
                                  styles.buttonDisabled
                              )}
                              disabled={
                                availableOperations?.regenerate === false
                              }
                              onClick={() => {
                                GenerationService.RegenerateCreation(
                                  currentCreation
                                ).then(() => {
                                  onGenerate();
                                });
                                onClose();
                              }}
                              data-testid="regenerate"
                            >
                              <Icon name="regenerate" size="30" />
                            </button>
                          </CustomTooltip>
                        </>
                      )}
                  </>
                ) : (
                  <>
                    <button
                      className={cn("button-gray", styles.button)}
                      onClick={() => {
                        clearDrawing();
                      }}
                      data-testid="clear drawing"
                    ></button>
                    <div style={{ position: "relative" }}>
                      <button className="button-gray"></button>
                    </div>
                    <button
                      className={cn("button-gray", styles.button)}
                    ></button>
                    <button
                      className={cn("button-gray", styles.button)}
                    ></button>

                    {eraseModeEnabled && (
                      <>
                        <button
                          className={cn("button-gray", styles.button)}
                        ></button>
                        <button
                          className={cn("button-gray", styles.button)}
                        ></button>
                      </>
                    )}
                    {editModeEnabled && (
                      <>
                        <button
                          className={cn("button-gray", styles.button)}
                        ></button>
                        <button
                          className={cn("button-gray", styles.button)}
                        ></button>
                        <button
                          className={cn("button-gray", styles.button)}
                        ></button>
                      </>
                    )}
                  </>
                )}
                {magicCutModeEnabled && (
                  <>
                    <button
                      className={cn("button-gray", styles.button)}
                    ></button>
                  </>
                )}

                {!isGalleryPage &&
                  !editModeEnabled &&
                  !eraseModeEnabled &&
                  !magicCutModeEnabled &&
                  currentCreation?.creation_type !== "ui_component" &&
                  !artifact?.use_flux && (
                    <>
                      <div style={{ position: "relative" }}>
                        <CustomTooltip
                          id="paintOver"
                          title={TooltipData["PaintOver"]}
                          placement="top"
                        >
                          <button
                            // className={cn("button-gray", styles.button)}
                            className={cn(
                              "button-gray",
                              !availableOperations?.paint_over &&
                                styles.buttonDisabled
                            )}
                            onClick={handlePaintOverMode}
                            data-testid="paintOver"
                            disabled={availableOperations?.paint_over === false}
                          >
                            {paintOverModeEnabled ? (
                              <Icon
                                src="/images/svg/paint-over-icon-dark.png"
                                size="44.5"
                              />
                            ) : (
                              <Icon
                                src="/images/svg/paint-over-icon.png"
                                size="44.5"
                              />
                            )}
                          </button>
                        </CustomTooltip>

                        {paintOverModeEnabled && (
                          <div
                            style={{
                              position: "absolute",
                              marginTop: "1px",
                              zIndex: 10,
                            }}
                          >
                            <div style={{ display: "flex", gap: 0.5 }}>
                              <Painter
                                setBrushColor={setBrushColor}
                                setPickedColor={setPickedColor}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                {!isGalleryPage &&
                  !editModeEnabled &&
                  !eraseModeEnabled &&
                  !paintOverModeEnabled &&
                  ![
                    COMMUNIX_ORGANIZATION_ID,
                    HALFBITE_ORGANIZATION_ID,
                  ].includes(currentCreation?.organization_id) && (
                    <>
                      <CustomTooltip
                        id="magicCut"
                        title={"Magic Cut"}
                        placement="top"
                      >
                        <button
                          // className={cn("button-gray", styles.button)}
                          className={cn(
                            "button-gray",
                            availableOperations?.magic_cut === false &&
                              styles.buttonDisabled
                          )}
                          onClick={() => {
                            handleMagicCutMode();
                            if (!magicCutModeEnabled) {
                              toast.promise(
                                handleGetImageEmbeddings().then(
                                  (embeddingResponse) => {
                                    setEmbeddingData(
                                      embeddingResponse["result"]
                                    );
                                  }
                                ),
                                {
                                  loading:
                                    "Seperating image layers, \n This may take a while...",
                                  success: "Image embeddings retrieved!",
                                  error: (error) => {
                                    handleClearPoints();
                                    setEmbeddingData(null);
                                    handleMagicCutMode();

                                    return `Failed to retrieve image embeddings: ${error.message}`;
                                  },
                                }
                              );
                            } else {
                              handleClearPoints();
                              setEmbeddingData(null);
                            }
                          }}
                          data-testid="magicCut"
                          disabled={availableOperations?.magic_cut === false}
                        >
                          {eraseModeEnabled ? (
                            <Icon src="/images/svg/magix.png" size="35.5" /> // magic-cut-icon-dark
                          ) : (
                            <Icon src="/images/svg/magix.png" size="35.5" />
                          )}
                        </button>
                      </CustomTooltip>
                    </>
                  )}

                {!isGalleryPage &&
                  !editModeEnabled &&
                  !paintOverModeEnabled &&
                  !magicCutModeEnabled &&
                  currentCreation?.creation_type !== "ui_component" &&
                  !artifact?.use_flux && (
                    <>
                      <CustomTooltip
                        id="erase"
                        title={TooltipData["Erase"]}
                        placement="top"
                      >
                        <button
                          // className={cn("button-gray", styles.button)}
                          className={cn(
                            "button-gray",
                            availableOperations?.erase === false &&
                              styles.buttonDisabled
                          )}
                          onClick={handleEraseMode}
                          data-testid="erasePainting"
                          disabled={availableOperations?.erase === false}
                        >
                          {eraseModeEnabled ? (
                            <Icon
                              src="/images/svg/eraser-filled.svg"
                              size="34"
                            />
                          ) : (
                            <Icon src="/images/svg/eraser.svg" size="34" />
                          )}
                        </button>
                      </CustomTooltip>
                    </>
                  )}

                {!isGalleryPage &&
                  !eraseModeEnabled &&
                  !paintOverModeEnabled &&
                  !magicCutModeEnabled &&
                  currentCreation?.creation_type !== "ui_component" &&
                  !artifact?.use_flux && (
                    <>
                      <CustomTooltip
                        id="Inpainting"
                        title={TooltipData["Inpainting"]}
                        placement="top"
                      >
                        <button
                          // className={cn("button-gray", styles.button)}
                          className={cn(
                            "button-gray",
                            availableOperations?.inpainting === false &&
                              styles.buttonDisabled
                          )}
                          onClick={handleEditMode}
                          data-testid="inPainting"
                          disabled={availableOperations?.inpainting === false}
                        >
                          {editModeEnabled ? (
                            <Icon src="/images/svg/edit-filled.svg" size="30" />
                          ) : (
                            <Icon src="/images/svg/edit.svg" size="30" />
                          )}
                        </button>
                      </CustomTooltip>
                    </>
                  )}
                {eraseModeEnabled && (
                  <button className={cn("button-gray", styles.button)}></button>
                )}
                {paintOverModeEnabled && (
                  <>
                    <button
                      className={cn("button-gray", styles.button)}
                    ></button>
                    <button
                      className={cn("button-gray", styles.button)}
                    ></button>
                    <button
                      className={cn("button-gray", styles.button)}
                    ></button>
                  </>
                )}
                {magicCutModeEnabled && (
                  <>
                    <button
                      className={cn("button-gray", styles.button)}
                    ></button>
                    <button
                      className={cn("button-gray", styles.button)}
                    ></button>
                  </>
                )}
              </div>
            </div>
            {/* TODO:
            replace the ["character", "item"] with settings pulled from the DB,
            it should be something like availableOperations?.generate_triple_offer === false
            */}
            <Panel
              download={download}
              galleryPanel={gallery}
              like={true}
              item={currentCreation}
              onGenerateTripleOffer={
                ["character", "item"].includes(currentCreation?.creation_type)
                  ? handleGenerateTripleOffer
                  : null
              }
              onGeneratePersonalOffer6={
                ["character", "item"].includes(currentCreation?.creation_type)
                  ? handleGeneratePersonalOffer6
                  : null
              }
              onGenerateDecoy={
                ["item"].includes(currentCreation?.creation_type)
                  ? handleGenerateDecoy
                  : null
              }
              isEditMode={editModeEnabled}
              setEditMode={setIsEditModeEnabled}
              isEraseMode={eraseModeEnabled}
              setEraseMode={setIsEraseModeEnabled}
              isPaintOverMode={paintOverModeEnabled}
              setPaintOverMode={setIsPaintOverModeEnabled}
              isMagicCutMode={magicCutModeEnabled}
              setMagicCutMode={setIsMagicCutModeEnabled}
            />

            {items && (
              <div className={styles.wrapper}>
                <Slider className="photo-slider" {...settings} ref={sliderRef}>
                  {items.map((item, index) => (
                    <div className={styles.slide} key={index}>
                      <div className={styles.preview}>
                        {!item.placeholder ? (
                          item.creation_type === "triple-rolling-offer" ? (
                            <TripleRollingOfferOption creation={item} />
                          ) : item.creation_type === "personal-offer-6" ? (
                            <PersonalOffer6Option creation={item} />
                          ) : (
                            <>
                              {editModeEnabled ||
                              eraseModeEnabled ||
                              paintOverModeEnabled ||
                              magicCutModeEnabled ? (
                                <WatermarkedImage
                                  ref={watermarkedImageRef}
                                  editMode={editModeEnabled}
                                  eraseMode={eraseModeEnabled}
                                  paintOverMode={paintOverModeEnabled}
                                  magicCutMode={magicCutModeEnabled}
                                  handleMagicCutClick={handleMagicCutClick}
                                  creation={item}
                                  src={
                                    item.result_image_url &&
                                    item.result_image_url[0]
                                  }
                                  watermarkSrc={`${process.env.PUBLIC_URL}/images/logo-light.png`}
                                  onMaskCanvasUpdate={(maskCanvasDataUrl) => {
                                    setMaskCanvasDataUrl(maskCanvasDataUrl);
                                  }}
                                  brushColor={brushColor}
                                  pickedColor={pickedColor}
                                  setBrushColor={setBrushColor}
                                  onDimensionsChange={handleDimensionsChange}
                                />
                              ) : (
                                <img
                                  src={
                                    item.result_image_url &&
                                    item.result_image_url[0]
                                  }
                                  alt={item.creation_type}
                                  style={{
                                    borderRadius: "16px",
                                    maxHeight: 500,
                                    maxWidth: 500,
                                  }}
                                />
                              )}
                            </>
                          )
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
                {editModeEnabled && (
                  <>
                    <PromptInput
                      prompt={editModePrompt}
                      placeholder={"Type a prompt to generate"}
                      setPrompt={setEditModePrompt}
                      validateInputThenGenerateCreations={
                        validateInputThenGenerateCreations
                      }
                      value={editModePrompt}
                      onClose={() => {
                        onGenerate();
                        onClose();
                      }} // Pass onClose to PromptInput
                      style={{
                        width: "560px",
                        height: "100px",

                        margin: "0 auto",
                        marginTop: "30px",
                      }}
                    />
                    <center>
                      <button
                        className="button"
                        onClick={validateInputThenGenerateCreations}
                        style={{
                          marginTop: "10px",
                          margin: "0 auto",
                          backgroundColor: "#823bf5",
                          fontSize: "17px",
                        }}
                      >
                        Generate
                      </button>
                    </center>
                  </>
                )}
                {eraseModeEnabled && (
                  <>
                    <center>
                      <button
                        className="button"
                        onClick={eraseThenGenerateCreations}
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#823bf5",
                          fontSize: "17px",
                        }}
                      >
                        Erase
                      </button>
                    </center>
                  </>
                )}
                {paintOverModeEnabled && (
                  <>
                    <center>
                      {/* <Painter/> */}
                      <button
                        className="button"
                        onClick={paintOverThenGenerateCreations}
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#823bf5",
                          fontSize: "17px",
                        }}
                      >
                        Render
                      </button>
                    </center>
                  </>
                )}
                {magicCutModeEnabled && (
                  <>
                    <center>
                      {/* <Painter/> */}
                      {embeddingData && inputLabelsList && (
                        <>
                          <button
                            className="button"
                            onClick={handleDownloadSegmentedArea}
                            style={{
                              marginTop: "10px",
                              backgroundColor: "#823bf5",
                              fontSize: "17px",
                              marginRight: "10px",
                            }}
                          >
                            Download Selected Area
                          </button>
                          {inputPointsList.length > 0 && (
                            <button
                              className="button"
                              onClick={handleClearPoints}
                              style={{
                                marginTop: "10px",
                                backgroundColor: "#823bf5",
                                fontSize: "17px",
                              }}
                            >
                              Clear Points
                            </button>
                          )}
                        </>
                      )}
                    </center>
                  </>
                )}
              </div>
            )}
          </>
        </div>
      </div>
    ),
    document.body
  );
};

export default ModalProduct;
