import React from "react";
import { MentionsInput, Mention } from "react-mentions";
import styles from "./PromptBox.module.sass";
import mentionStyle from "./mentionStyle";
import { toast } from "react-hot-toast";

const PROMPT_MAX_LENGTH = 800;

const PromptInput = ({
  placeholder,
  prompt,
  setPrompt,
  validateInputThenGenerateCreations,
  isDisabled,
  inputRef,
  charactersSuggestions,
  itemsSuggestions,
  isUsingAssistant,
  style,
}) => {

  return (
    <MentionsInput
      value={isUsingAssistant ? prompt : prompt}
      onChange={(e) => {
        if (e.target.value.length <= PROMPT_MAX_LENGTH) {
          setPrompt(e.target.value);
        } else {
          toast.error("Prompt is too long. Please shorten it.");
        }
      }}
      onKeyDown={(event) => {
        if (event.shiftKey && event.key === "Enter") {
          event.preventDefault();
        }
        if (event.key === "Enter" && !event.shiftKey) {
          event.preventDefault();
          validateInputThenGenerateCreations();
        }
      }}
      className={styles.input}
      placeholder={placeholder}
      disabled={isDisabled}
      inputRef={inputRef}
      style={{ ...mentionStyle, ...style }}
    >
      <Mention
        trigger="@"
        data={charactersSuggestions}
        markup="@__id__@"
        displayTransform={(id, display) => `@${display}`}
        style={mentionStyle.characterMention}
      />
      <Mention
        trigger="#"
        data={itemsSuggestions}
        markup="#__id__#"
        displayTransform={(id, display) => `#${display}`}
        style={mentionStyle.assetMention}
      />
    </MentionsInput>
  );
};

export default PromptInput;
