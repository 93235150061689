import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthProvider } from "./contexts/AuthContext";
import PermissionsProvider from "./contexts/PermissionsContext";
import { DialogProvider } from "./contexts/DialogContext";
import { Toaster } from "react-hot-toast";
import { Auth0Provider } from "@auth0/auth0-react";

import { msalConfig } from "./authConfig/authConfig";

import { LoadingBarProvider } from "./contexts/LoadingBarContext";
import { ModalPreviewProvider } from "./contexts/ModalPreviewContext";
import App from "./App";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain="dev-7augrdf4t2x6yvmy.us.auth0.com"
    clientId="RiTDYujG5C08pbcGm2Cy8JSSm6zqfxpd"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://dev-7augrdf4t2x6yvmy.us.auth0.com/api/v2/",
    }}
  >
    <LoadingBarProvider>
      <PermissionsProvider>
        <AuthProvider>
          <BrowserRouter>
            <DialogProvider>
              <ModalPreviewProvider>
                <App />
              </ModalPreviewProvider>
            </DialogProvider>
          </BrowserRouter>
        </AuthProvider>
      </PermissionsProvider>
      <Toaster />
    </LoadingBarProvider>
  </Auth0Provider>
);
