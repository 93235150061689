import React, { useState, useEffect } from 'react';
import styles from './InputMatrix.module.sass';
import Icon from '../Icon';
import CustomTooltip from "../CustomTooltip/index";
import TooltipData from "../CustomTooltip/TooltipsData";

const InputMatrix = ({ isDisabledList, textsList, setTextsList, isAllowedRegenerateList, regenerateCreation, artifact }) => {
  const [keysList, setKeysList] = useState(Object.keys(textsList));
  console.log('keysList:', keysList);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editingPrompt, setEditingPrompt] = useState({ key: null, value: "" });

  useEffect(() => {
    setKeysList(Object.keys(textsList)); // Update keysList whenever textsList changes
  }, [textsList]);

  const handleRegenerateClick = (index) => {
    console.log("index", index);
    const x = Math.floor(index / 3); // Calculate row index (x)
    const y = index % 3; // Calculate column index (y)
    console.log("x, y", x, y);
    regenerateCreation(x, y);
  };

  const handleEditCardPrompt = (key) => {
    setEditingPrompt({ key, value: textsList[key] }); // Set the key and current value
    setIsPopupOpen(true);
  };

  const handleSavePrompt = () => {
    const newTextsList = { ...textsList, [editingPrompt.key]: editingPrompt.value };
    setTextsList(newTextsList); // Update textsList in the parent component
    setIsPopupOpen(false);
  };

  const handleInputChange = (index, value) => {
    const key = keysList[index];
    const newTextsList = { ...textsList, [key]: value };
    setTextsList(newTextsList);
  };

  const renderMatrix = () => {
    return keysList.map((key, index) => (
      <div className={styles.inputButtonContainer} key={index}>
        <input
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              // onRegenerateClicked(index);
              regenerateCreation(index);
            }
          }}
          className={styles.smallInput}
          type="text"
          placeholder={`Card ${index + 1} Key`}
          value={key}
          onChange={(e) => handleInputChange(index, e.target.value)}
          disabled={isDisabledList[index]}
        />
        <CustomTooltip title={TooltipData["EditPrompt"]} placement="top">
          <button onClick={() => handleEditCardPrompt(key)} className={styles.button}>
            <Icon name="edit" size="18" />
          </button>
        </CustomTooltip>
        {isAllowedRegenerateList[index] && (
          <CustomTooltip title={TooltipData["Regenerate"]} placement="top">
            {/* <button onClick={() => onRegenerateClicked(index)} className={styles.button}> */}
            <button onClick={() => handleRegenerateClick(index)} className={styles.button}>
              <Icon name="repeat" />
            </button>
          </CustomTooltip>
        )}
      </div>
    ));
  };

  return (
    <div className={styles.matrix}>
      {renderMatrix()}
      {isPopupOpen && (
        <>
          <div className={styles.overlay} onClick={() => setIsPopupOpen(false)} />
          <div className={styles.popup}>
            <div className={styles.popupContent}>
              <h3>Edit Prompt: {editingPrompt.key}</h3>
              <textarea
                style={{ width: "100%", height: "100px", fontSize: "16px" }}
                value={editingPrompt.value}
                onChange={(e) => setEditingPrompt({ ...editingPrompt, value: e.target.value })}
              />
              <div>
                <button
                  style={{
                    backgroundColor: "#823bf5",
                    width: "40%",
                    height: '30px',
                    borderRadius: "5px",
                    marginRight: '10px',
                    fontSize: '17px',
                    color: 'white',
                    fontWeight: 'bold'
                  }}
                  onClick={handleSavePrompt}
                >
                  Save
                </button>
                <button
                  style={{
                    backgroundColor: "#823bf5",
                    width: "40%",
                    height: '30px',
                    borderRadius: "5px",
                    fontSize: '17px',
                    color: 'white',
                    fontWeight: 'bold'
                  }}
                  onClick={() => setIsPopupOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InputMatrix;
