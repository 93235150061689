import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-hot-toast";
import { useAuth } from "./AuthContext";
import ApiService from "../services/ApiService";
import OrganizationKeyEntry from "../screens/OrganizationKeyEntry";

export default function RequireAuth() {
  // Auth0 hooks
  const {
    isAuthenticated,
    isLoading: isAuthLoading,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    user,
  } = useAuth0();

  const { getArtifacts, getOrganizationData } = useAuth();

  // Local state
  const [isLoadingOrgData, setIsLoadingOrgData] = useState(true);
  const [organizationKey, setOrganizationKey] = useState(""); // State for organization key
  const [organizationNotFound, setOrganizationNotFound] = useState(false); // Organization not found state

  // Handle organization key submission
  const handleOrganizationKeySubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.post("/set_organization_key", {
        organization_key: organizationKey,
      });
      if (response.success) {
        setIsLoadingOrgData(true); // Start loading after setting the key
        await getOrganizationData(); // Reload organization data after setting the key
        await getArtifacts(); // Fetch artifacts after setting the key
        setOrganizationNotFound(false);
      } else {
        toast.error("Invalid organization key. Please try again.");
      }
    } catch (error) {
      console.error("Failed to set organization key:", error);
      toast.error("Failed to set organization key. Please try again.");
    } finally {
      setIsLoadingOrgData(false); // Ensure loading stops regardless of success or failure
    }
  };

  useEffect(() => {
    let isMounted = true;

    // Main authentication and data fetching logic
    const initializeAuth = async () => {
      // If user is not authenticated and auth process is complete, redirect to login
      if (!isAuthenticated && !isAuthLoading) {
        loginWithRedirect();
        return;
      }

      // If user is authenticated and we need to load org data
      if (isAuthenticated && isLoadingOrgData) {
        try {
          // Get Auth0 access token
          const accessToken = await getAccessTokenSilently({
            audience: "https://dev-7augrdf4t2x6yvmy.us.auth0.com/api/v2/",
          });

          if (!isMounted) return;

          // Set token and fetch necessary data
          ApiService.setAccessToken(accessToken);
          let orgData;
          try {
            orgData = await getOrganizationData();

            console.log(orgData);
            if (orgData) {
              setOrganizationNotFound(false);
              await getArtifacts(); // Fetch artifacts if org data exists
            } else {
              setOrganizationNotFound(true); // Organization data not found
            }
          } catch {
            setOrganizationNotFound(true); // Organization not found on error
          } finally {
            setIsLoadingOrgData(false); // Ensure loading stops regardless of success or failure
          }
        } catch (error) {
          console.error("Initialization failed:", error);
          toast.error("Authentication failed. Please try again.");
          // timeout before redirecting to login
          setTimeout(() => {
            localStorage.clear();
            logout({ returnTo: window.location.origin });
          }, 2000);
        }
      }
    };

    initializeAuth();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [isAuthenticated, isAuthLoading]);

  // Inline styles for loader
  const loaderContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
  };

  const loaderStyle = {
    border: "16px solid #f3f3f3", // Light grey
    borderTop: "16px solid #823bf5",
    borderRadius: "50%",
    width: "120px",
    height: "120px",
    animation: "spin 2s linear infinite",
  };

  // Keyframes for the spinning animation
  const spinnerKeyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  // Render logic
  if (isAuthLoading || isLoadingOrgData) {
    return (
      <div style={loaderContainerStyle}>
        <style>{spinnerKeyframes}</style>
        <div style={loaderStyle}></div>
      </div>
    );
  }

  // If organization not found, prompt for the organization key
  if (organizationNotFound) {
    return (
      <OrganizationKeyEntry
        organizationKey={organizationKey}
        setOrganizationKey={setOrganizationKey}
        onSubmit={handleOrganizationKeySubmit}
        user={user}
      />
    );
  }

  return <Outlet />;
}
