import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import { useAuth } from "../../contexts/AuthContext"; // Adjust the import path to your AuthProvider's location
import { useNavigate } from "react-router-dom";

import User from "./User";
const Header = ({ onOpen }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    onOpen();
    setVisible(false);
  };
  return (
    <header className={styles.header}>
      <button className={styles.burger} onClick={() => handleClick()}></button>
      <button
        className={cn(styles.buttonSearch, { [styles.active]: visible })}
        onClick={() => setVisible(!visible)}
      >
        <Icon name="search" size="24" />
      </button>
      <div className={styles.control} onClick={() => setVisible(false)}>
        <a
          onClick={() => {
            navigate("/add_data");
          }}
          style={{ pointerEvents: "auto", cursor: "pointer" }}
        >
          <Icon src="/images/svg/cloud.svg" size="32" />
        </a>

        <User />
      </div>
    </header>
  );
};
export default Header;
