// import React, { useEffect, useState } from "react";
// import cn from "classnames";
// import styles from "./Loader.module.sass";

// const Loader = ({ className, white }) => {
//   const [seconds, setSeconds] = useState(0);

//   useEffect(() => {
//     // Start a timer when the component mounts
//     const interval = setInterval(() => {
//       setSeconds((prevSeconds) => prevSeconds + 1);
//     }, 1000);

//     // Cleanup interval on component unmount
//     return () => clearInterval(interval);
//   }, []);

//   useEffect(() => {
//     if(seconds >= 1000){
//       setSeconds(0);
//     }
//   }, [seconds]);

//   return (
//     <div className={styles.loaderContainer}>
//       <div
//         className={cn(styles.loader, className, {
//           [styles.loaderWhite]: white,
//         })}
//       />
//         <div className={styles.timer}>{seconds}</div>
//     </div>
//   );
// };

// export default Loader;


import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Loader.module.sass";

const Loader = ({ className, white, timestamp_creation_id, time_of_click }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    console.log('timestamp_creation_id:',timestamp_creation_id);
    console.log('time_of_click:',time_of_click);

    const creationTimeInSeconds = timestamp_creation_id ? (timestamp_creation_id / 1e9) : time_of_click;
    // Get current time in seconds
    const currentTimeInSeconds = Date.now() / 1000;
    console.log('currentTimeinSeconds:',currentTimeInSeconds, 'creationTimeinsseconds:',creationTimeInSeconds);

    // Calculate the initial seconds difference
    const initialSeconds = Math.floor(currentTimeInSeconds - creationTimeInSeconds);

    // Set the initial seconds
    setSeconds(initialSeconds);

    // Start a timer to update the seconds every second
    const interval = setInterval(() => {
      setSeconds(prevSeconds => {
        if (prevSeconds >= 1000) {
          // Reset the counter if it exceeds 1000 seconds
          return 0;
        }
        return prevSeconds + 1;
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [timestamp_creation_id]);

  return (
    <div className={styles.loaderContainer}>
      <div
        className={cn(styles.loader, className, {
          [styles.loaderWhite]: white,
        })}
      />
      <div className={styles.timer}>{seconds}</div>
    </div>
  );
};

export default Loader;
