import React, { createContext, useContext, useState } from "react";
import ModalPreview from "../components/ModalPreview";

const ModalPreviewContext = createContext();

export const useModalPreview = () => useContext(ModalPreviewContext);

export const ModalPreviewProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    visible: false,
    items: [],
    title: "",
    artifact: null,
    currentViewingItemIndex: null,
    onGenerateCallback: null,
  });

  const openModal = (items, index, artifact, onGenerateCallback) => {
    setModalState({
      visible: true,
      items,
      currentViewingItemIndex: index,
      artifact: artifact,
      onGenerateCallback: onGenerateCallback,
    });
  };

  const handleGenerate = () => {
    if (
      modalState.onGenerateCallback &&
      typeof modalState.onGenerateCallback === "function"
    ) {
      modalState.onGenerateCallback();
    }
  };

  const closeModal = () => {
    setModalState((prev) => ({
      ...prev,
      visible: false,
      items: [],
      currentViewingItemIndex: null,
    }));
  };

  const setCurrentViewingItemIndex = (index) => {
    setModalState((prev) => ({ ...prev, currentViewingItemIndex: index }));
  };

  return (
    <ModalPreviewContext.Provider
      value={{
        modalState,
        openModal,
        closeModal,
        handleGenerate,
        setCurrentViewingItemIndex,
      }}
    >
      {children}
      <ModalPreview
        visible={modalState.visible}
        onClose={closeModal}
        onGenerate={handleGenerate}
        items={modalState.items}
        download={true}
        like={true}
        currentViewingItemIndex={modalState.currentViewingItemIndex}
        artifact={modalState.artifact}
      />
    </ModalPreviewContext.Provider>
  );
};
