// UnifiedSignIn.js
import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass"; // Assuming both use similar styles; adjust if different
import { use100vh } from "react-div-100vh";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useLoadingBar } from "../../contexts/LoadingBarContext";

import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import { useAuth } from "../../contexts/AuthContext";
import { useAuth0 } from "@auth0/auth0-react";

const UnifiedSignIn = () => {
  const heightWindow = use100vh();
  const { signIn, signInWithAD } = useAuth(); // Use both signIn methods

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [organizationKey, setOrganizationKey] = useState(""); // Added state for organization key
  const [organizaitonKeyRequired, setOrganizationKeyRequired] = useState(false); // Added state to manage organization key requirement
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await signIn(email, password, organizationKey); // Pass organization key to signIn function
      navigate("/");
    } catch (error) {
      if (error.message.includes("User is not part of any organization")) {
        toast.error("Please enter the organization key");
        setOrganizationKeyRequired(true);
      } else {
        toast.error("Sign In failed: " + error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignInWithAD = async () => {
    try {
      setIsLoading(true);
      await signInWithAD();
      navigate("/");
    } catch (error) {
      console.error(error);
      toast.error("Microsoft Sign In failed: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
        <div className={cn("h2", styles.title)}>Sign in</div>
        <form onSubmit={handleSignIn}>
          <TextInput
            className={styles.field}
            name="email"
            type="email"
            placeholder="Your email"
            required
            icon="mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextInput
            className={styles.field}
            name="password"
            type="password"
            placeholder="Password"
            required
            icon="lock"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {organizaitonKeyRequired && (
            <TextInput
              name="organizationKey"
              type="text"
              placeholder="Organization Key"
              required={organizaitonKeyRequired}
              icon="lock"
              value={organizationKey}
              onChange={(e) => setOrganizationKey(e.target.value)}
              // style={{marginBottom: "10px"}}
            />
          )}
          <button
            className={cn("button", styles.button)}
            style={{ width: "100%", marginTop: "10px" }}
            type="submit"
          >
            {!isLoading ? "Sign in" : "Please wait..."}
          </button>
        </form>
        <br></br>
        <center>Or log in with: </center>
        <br></br>
        <button
          className={cn("button", styles.button, styles.adButton)}
          style={{ width: "100%" }}
          onClick={handleSignInWithAD}
          type="button"
        >
          {!isLoading ? (
            <>
              {" "}
              <object
                type="image/svg+xml"
                data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
                className={styles.icon}
              ></object>
              Sign in with Microsoft
            </>
          ) : (
            "Please wait..."
          )}
        </button>
        <div className={styles.info}>
          Don’t have an account?{" "}
          <Link className={styles.link} to="/sign-up">
            Sign up
          </Link>
          <br></br>
          <Link className={styles.link} to="/forgot-password">
            Forgot Password?
          </Link>
        </div>
        <div className={styles.info}></div>
      </div>
      <button onClick={() => {}}>Log In</button>
    </div>
  );
};

export default UnifiedSignIn;
