import React, { createContext, useContext, useState, useEffect } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig/authConfig";
import { usePermissions } from "./PermissionsContext";
import { toast } from "react-hot-toast";
import { useLoadingBar } from "./LoadingBarContext";
import ApiService from "../services/ApiService";

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

export const AuthProvider = ({ children }) => {
  const { progress, setProgress } = useLoadingBar();
  const [currentUser, setCurrentUser] = useState(() => {
    const userFromStorage = localStorage.getItem("currentUser");
    return userFromStorage ? JSON.parse(userFromStorage) : null;
  });
  const { setPermissions } = usePermissions();
  const [artifacts, setArtifacts] = useState([]);
  const [organizationData, setOrganizationData] = useState({});

  function groupByType(artifacts) {
    return artifacts.reduce((acc, artifact) => {
      if (!acc[artifact.type]) {
        acc[artifact.type] = [];
      }
      acc[artifact.type].push(artifact);
      return acc;
    }, {});
  }

  const getArtifacts = async () => {
    try {
      const response = await ApiService.get("/get_artifacts");
      setArtifacts(response?.data);
      return response?.data;
    } catch (error) {
      toast.error("Failed to get artifacts");
      console.error(error);
      throw error;
    }
  };

  const getOrganizationData = async () => {
    try {
      const response = await ApiService.get("/get_organization_data");
      console.log(response);
      setOrganizationData(response);
      setPermissions(response.feature_flags_policy);
      return response;
    } catch (error) {
      // toast.error("Failed to get organization data");
      console.error(error);
      throw error;
    }
  };

  const signUp = async (email, password) => {
    try {
      // Use ApiService to post registration data
      const data = await ApiService.post("/register", { email, password });
      console.log(data);
      toast.success("User registered successfully");
      console.log("User registered successfully", data);
    } catch (error) {
      console.error("Registration failed", error);
      throw error; // Propagate the error to be handled where login is called
    }
  };

  const signIn = async (username, password, organizaitonKey) => {
    console.log(username, password, organizaitonKey);
    try {
      setProgress(20);
      const response = await ApiService.post("/login", {
        username,
        password,
        organization_key: organizaitonKey,
      });
      console.log(response);
      setCurrentUser({ username: username, method: "username/password" });
      localStorage.setItem(
        "currentUser",
        JSON.stringify({ username: username, method: "username/password" })
      );
      ApiService.setAccessToken(response.AccessToken);
      ApiService.setRefreshToken(response.RefreshToken);
      localStorage.setItem("darkMode", false);
      await getArtifacts();
      setProgress(60);
      await getOrganizationData();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setProgress(100);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        artifacts,
        organizationData,
        getArtifacts,
        getOrganizationData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
